.lea-cards-header {
padding: 30px 15px 0 15px;
text-align: left;
}

.lea-cards-header > * {
margin-bottom: 30px;
display: inline-block;
width: 33.333333%;
}
  
/* very nice idea from : https://stackoverflow.com/questions/8720931/can-css-detect-the-number-of-children-an-element-has*/
.lea-cards-header > *:first-child:nth-last-child(4) ~ *:not(:first-child) {
width: 22.222222%;
}

.lea-cards-header .card {
background-color: #FFFFFF;
}
  
.lea-cards-header > *:first-child .card {
background-color: #00EBB8;
}
  
.lea-cards-header > *:first-child .card .title,
.lea-cards-header > *:first-child .card .subtitle,
.lea-cards-header > *:first-child .card .counter {
color: #FFFFFF;
}

@media (max-width: 1200px) {

  .lea-cards-header > *:first-child:nth-last-child(4) ~ *:not(:first-child) {
  width: 33.333333%;
  }

  .lea-cards-header > *:first-child:nth-last-child(2),
  .lea-cards-header > *:first-child:nth-last-child(2) ~ *  {
  width: 50%;
  }

  .lea-cards-header > *:first-child:nth-last-child(4) ~ *:nth-last-child(3) {
  display:none;
  }
}

@media (max-width: 750px) {

  .lea-cards-header > *:first-child:nth-last-child(2),
  .lea-cards-header > *:first-child {
  width: 100%;
  }

  .lea-cards-header > *:first-child:nth-last-child(2) ~ *:not(:first-child)  {
  display: none;
  }
  
  .lea-cards-header > *:first-child:nth-last-child(3) ~ * {
  width: 50%;
  }

  .lea-cards-header > *:first-child:nth-last-child(4) ~ *:not(:first-child) {
  display:none;
  }
}