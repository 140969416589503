.mydrp {
width: auto !important;
border: 1pw solid #B0B0B1 !important;
}

.mydrp *:not(.mydrpicon) {
font-family: 'Roboto' !important;
font-weight: 400 !important;
color: #3D4759 !important;
}

.mydrp .daycell {
position: relative !important;
height: 28px !important;
padding: 0 !important; 
background-color: #FFFFFF !important;
}

.mydrp .daycell > div {
position: absolute !important;
top: 0 !important;
bottom: 0 !important;
left: 0 !important;
right: 0 !important;
line-height: 28px !important;
vertical-align: middle !important;
}

.mydrp .datevalue.prevmonth span,
.mydrp .datevalue.nextmonth span {
color: #B0B0B1 !important;
}

.mydrp .datevalue.prevmonth span,
.mydrp .datevalue.nextmonth span {
color: #B0B0B1 !important;
line-height: auto !important;
}

.mydrp .mydrpicon {
color: #A8AAB7 !important;
}

.mydrp .mydrpicon:hover {
color: #34495e !important;
}
      
.mydrp .weekdaytitle {
background-color: #FFFFFF !important;
border-top: 1px solid #EFF1F4 !important;
}

.mydrp .selecteddaybegin,
.mydrp .range > div:not(.selecteddaybegin):not(.selecteddayend),
.mydrp .selecteddayend {
background-color: #00EBB8 !important;
border-radius: 0 !important;
}

.mydrp .daycell:focus {
outline: none !important  ;
}

.mydrp .selecteddaybegin {
border-top-left-radius: 14px !important;
border-bottom-left-radius: 14px !important;
}

.mydrp .selecteddayend,
.mydrp .range {
border-top-right-radius: 14px !important;
border-bottom-right-radius: 14px !important;
}