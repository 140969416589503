.lea-page-content {
padding: 30px;
}

.lea-page-container {
background-color: #ffffff;
border-radius: 6px;  
}

.lea-page-header {
color: #7F8FA4;
font-weight: bold;
padding-top: 5px 10px 5px 10px;
margin-bottom: 30px;
border-bottom-left-radius: 0;
border-bottom-right-radius: 0;
}

.lea-page-header + .lea-page-container {
border-top-left-radius: 0;
border-top-right-radius: 0;
}