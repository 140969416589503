.lea-form {
background-color: #FFFFFF;
text-align: center;
padding-top: 15px;
padding-bottom: 15px;
}

.lea-form-group {
padding-left: 15px;
padding-right: 15px;
margin-bottom: 10px;
}

.lea-form p {
margin-bottom: 8px;
padding-left: 15px;
padding-right: 15px;
}

.lea-form p,
.lea-form p a,
.lea-form p a:hover,
.lea-form p a:visited {
font-family: Lato, Helvetica, Arial, sans-serif;
font-size: 15px;
color: #34495e;
}

.lea-form .lea-comment p {
margin-top: 6px;
line-height: initial;
}


.lea-form .lea-comment p,
.lea-form .lea-comment p a,
.lea-form .lea-comment p a:hover,
.lea-form .lea-comment p a:visited {
font-size: 14px;
color: #CED0DA;
line-height: initial;
font-style: italic;
}


.lea-form p a,
.lea-form p a:hover,
.lea-form p a:visited,
.lea-form .lea-comment p a,
.lea-form .lea-comment p a:hover,
.lea-form .lea-comment p a:visited {
text-decoration: underline;
}
  
.lea-form > .lea-section {
text-align: left;
display: inline-block;
vertical-align: top;
width: 100%;
padding-left: 15px;
padding-right: 15px;
}

.lea-form .lea-title {
margin-top:15px;
padding-left: 15px;
text-align: left;
font-size: 22px;
line-height: 50px;
vertical-align: middle;
font-weight: 400;
}

.lea-form-label {
color: #7F8FA4;
font-size: 14px;
font-weight: bold;
line-height: 21px;
padding-bottom: 8px;
}


