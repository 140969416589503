.track {
stroke-width: 4px;
cursor: default;
stroke: #0B4D6E;
}

.track.selected {
stroke: #00EBB8;
}

.leaflet-popup-content {
margin: 12px;
margin-top: 16px;
min-width: 120px;
}

.tracking-popup {
text-align: left;
color: #34495e;
width: 220px;
}

.personal-popup {

  text-align: left;
  color: #34495e;
}

.personal-popup .title {
  font-weight: bold;
  font-size: 12px;
}

.personal-popup .title:first-letter {
  text-transform: capitalize;
}

.personal-popup .details {
  margin-top: 10px;
  font-size: 11px;
}

.tracking-popup .name {
font-weight: bold;
font-size: 12px;
}

.tracking-popup .battery {
line-height: 18px;
vertical-align: center;
color: #7F8FA4;
font-size: 10px;
padding-left: 22px;
background-image: url('/assets/images/icons8-batterie-pleine-30.png');
background-position: left center;
background-size: contain;
background-repeat: no-repeat;
}

.tracking-popup .details {
margin-top: 6px;
font-size: 10px;
}

.tracking-popup .details strong {
font-size: 10px;
font-weight: bold;
font-style: normal;
}

.tracking-popup .stop.details .traveled,
.tracking-popup .stop.details .start-addr {
margin-top: 6px;
}

.tracking-popup .button-box {
margin-top: 8px;
text-align: right;
}

.tracking-popup .button-box a,
.tracking-popup .button-box a:hover,
.tracking-popup .button-box a:visited {
display: inline-block;
cursor: pointer;
font-size: 12px;
color: #354052;
border-radius: 6px;
padding: 4px 8px;
text-decoration: none;
border: 2px solid rgba(0,0,0,0.1);
}

.tracking-popup .button-box a:hover {
background-color: #f3f5f9;
}

.route {
stroke-width: 4px;
cursor: default;
}

.route.selected {
stroke: #0B4D6E;
}

.route.highlighted {
stroke: #00EBB8;
}

.route.distracted {
stroke: #ff6e57;
}

.route.allowed {
  stroke: #93C47D;
}

.route.forbidden {
  stroke: #ff6e57;
}


.route.phoneused {
  stroke: #ff6e57;
  stroke-width: 6;
}

.SetMapPosBt {
background-image: url("/assets/images/map/svg/icons8-centre-direction_black.svg");
background-position: 50% 50%;
background-size: 20px 20px;
background-repeat: no-repeat;
width: 34px;
height: 34px;
border-radius: 4px;
border: 2px solid rgba(0,0,0,0.2);
background-color: #FFFFFF;
cursor: pointer;
transition: 0.25s;
}

.SetMapPosBt:hover {
background-color: #F4F4F4;
}
