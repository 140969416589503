.lea-w6 {
display: inline-block;
vertical-align: top;
width: 50%;
max-width: 50%;
}

.lea-w1 {
  display: inline-block;
  vertical-align: top;
  width: 8.3%;
  max-width: 8.3%;
}

.lea-w11 {
  display: inline-block;
  vertical-align: top;
  width: 91.6%;
  max-width: 91.6%;
}
@media (max-width: 768px) {

  .lea-md-w12 {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  max-width: 100%;
  }
}

@media (max-width: 540px) {

  .lea-xs-w12 {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  max-width: 100%;
  }
}

