/* You can add global styles to this file, and also import other style files */
@import '../node_modules/font-awesome/css/font-awesome.css';

@font-face {
font-family: 'AvenirNext';
src: url('assets/fonts/AvenirNextRoundedPro-Reg.eot');
src: url('assets/fonts/AvenirNextRoundedPro-Reg.eot') format('embedded-opentype'),
     url('assets/fonts/AvenirNextRoundedPro-Reg.woff') format('woff'),
     url('assets/fonts/AvenirNextRoundedPro-Reg.ttf') format('truetype'),
     url('assets/fonts/AvenirNextRoundedPro-Reg.svg') format('svg');
}

@font-face {
font-family: 'OpenSansSemiBold';
src: url('/assets/fonts/OpenSans-Semibold.eot');
src: url('/assets/fonts/OpenSans-Semibold.eot') format('embedded-opentype'),
     url('/assets/fonts/OpenSans-Semibold.woff') format('woff'),
     url('/assets/fonts/OpenSans-Semibold.ttf') format('truetype'),
     url('/assets/fonts/OpenSans-Semibold.svg') format('svg');
}

@font-face {
font-family: 'Roboto';
src: url('/assets/fonts/Roboto-Regular.eot');
src: url('/assets/fonts/Roboto-Regular.eot') format('embedded-opentype'),
     url('/assets/fonts/Roboto-Regular.woff') format('woff'),
     url('/assets/fonts/Roboto-Regular.ttf') format('truetype'),
     url('/assets/fonts/Roboto-Regular.svg') format('svg');
}


@font-face {
  font-family: "DriveQuant-Fleet-2021";
  src: url("/assets/fonts/DriveQuant-Fleet-2021.eot");
  src: url("/assets/fonts/DriveQuant-Fleet-2021.eot?#iefix") format("embedded-opentype"),
  url("/assets/fonts/DriveQuant-Fleet-2021.woff2") format("woff2"),
  url("/assets/fonts/DriveQuant-Fleet-2021.woff") format("woff"),
  url("/assets/fonts/DriveQuant-Fleet-2021.ttf") format("truetype"),
  url("/assets/fonts/DriveQuant-Fleet-2021.svg#font") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: "DriveQuant-Fleet-2021";
    src: url("/assets/fonts/DriveQuant-Fleet-2021.svg#DriveQuant-Fleet-2021") format("svg");
  }
}

[data-icons8]:before {
  content: attr(data-icons8);
}

.drivequant-fleet-2021-icons8-speedometer:before,
.icons8,
[data-icons8]:before {
  display: inline-block;
  font-family: "DriveQuant-Fleet-2021";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}


.drivequant-fleet-2021-icons8-speedometer:before {
  content: "\f100";
}


@font-face {
font-family: "DriveQuant-Fleet";
src: url("/assets/fonts/DriveQuant-Fleet.eot");
src: url("/assets/fonts/DriveQuant-Fleet.eot?#iefix") format("embedded-opentype"),
     url("/assets/fonts/DriveQuant-Fleet.woff2") format("woff2"),
     url("/assets/fonts/DriveQuant-Fleet.woff") format("woff"),
     url("/assets/fonts/DriveQuant-Fleet.ttf") format("truetype"),
     url("/assets/fonts/DriveQuant-Fleet.svg#DriveQuant-Fleet") format("svg");
font-weight: normal;
font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {

  @font-face {
    font-family: "DriveQuant-Fleet";
    src: url("/assets/fonts/DriveQuant-Fleet.svg#DriveQuant-Fleet") format("svg");
  }
}

[data-icons8]:before { content: attr(data-icons8); }

.icons8, [data-icons8]:before,
.icons8-add:before,
.icons8-add-2:before,
.icons8-add-3:before,
.icons8-add-4:before,
.icons8-add-5:before,
.icons8-add-filled:before,
.icons8-arrow-pointing-to-right:before,
.icons8-arrow-pointing-to-right-1:before,
.icons8-back:before,
.icons8-back-filled:before,
.icons8-bell:before,
.icons8-bell-2:before,
.icons8-brouillard-de-jour:before,
.icons8-brouillard-de-jour-1:before,
.icons8-brouillard-de-nuit:before,
.icons8-brouillard-de-nuit-1:before,
.icons8-calendar:before,
.icons8-calendar-filled:before,
.icons8-cancel:before,
.icons8-cancel-filled:before,
.icons8-car:before,
.icons8-car-2:before,
.icons8-car-3:before,
.icons8-car-4:before,
.icons8-centre-direction:before,
.icons8-centre-direction-1:before,
.icons8-cercl--a:before,
.icons8-cercl--a-1:before,
.icons8-cercl--b:before,
.icons8-cercl--b-1:before,
.icons8-checked:before,
.icons8-checked-checkbox:before,
.icons8-checked-checkbox-filled:before,
.icons8-checked-filled:before,
.icons8-checkmark:before,
.icons8-checkmark-filled:before,
.icons8-circled-down-right-2:before,
.icons8-circled-down-right-2-filled:before,
.icons8-circled-up-right-2:before,
.icons8-circled-up-right-2-filled:before,
.icons8-collapse-arrow:before,
.icons8-collapse-arrow-filled:before,
.icons8-compteur-de-vitesse:before,
.icons8-delete:before,
.icons8-delete-filled:before,
.icons8-doughnut-chart:before,
.icons8-doughnut-chart-2:before,
.icons8-drop-down-arrow:before,
.icons8-drop-down-arrow-2:before,
.icons8-drop-down-arrow-3:before,
.icons8-drop-down-arrow-4:before,
.icons8-effacer-les-filtres:before,
.icons8-effacer-les-filtres-2:before,
.icons8-en-construction:before,
.icons8-en-construction-1:before,
.icons8-explanation-mark:before,
.icons8-explanation-mark-1:before,
.icons8-external-link:before,
.icons8-external-link-filled:before,
.icons8-filtre:before,
.icons8-filtre-1:before,
.icons8-filtrer-et-trier:before,
.icons8-filtrer-et-trier-1:before,
.icons8-forward-button:before,
.icons8-forward-button-filled:before,
.icons8-gauche:before,
.icons8-gauche-1:before,
.icons8-gr-le:before,
.icons8-gr-le-1:before,
.icons8-group:before,
.icons8-group-2:before,
.icons8-help:before,
.icons8-help-2:before,
.icons8-histogramme:before,
.icons8-histogramme-1:before,
.icons8-hiver:before,
.icons8-hiver-1:before,
.icons8-info:before,
.icons8-info-2:before,
.icons8-journey:before,
.icons8-journey-filled:before,
.icons8-leaderboard:before,
.icons8-leaderboard-filled:before,
.icons8-leaf:before,
.icons8-leaf-1:before,
.icons8-lock:before,
.icons8-lock-filled:before,
.icons8-lune-et--toiles:before,
.icons8-lune-et--toiles-1:before,
.icons8-male-user:before,
.icons8-male-user-2:before,
.icons8-marqueur-a:before,
.icons8-marqueur-a-1:before,
.icons8-marqueur-b:before,
.icons8-marqueur-b-1:before,
.icons8-menu:before,
.icons8-menu-filled:before,
.icons8-modifier-le-filtre:before,
.icons8-modifier-le-filtre-1:before,
.icons8-neige:before,
.icons8-neige-1:before,
.icons8-nuages:before,
.icons8-nuages-1:before,
.icons8-ok:before,
.icons8-ok-filled:before,
.icons8-orage:before,
.icons8-orage-1:before,
.icons8-padlock:before,
.icons8-padlock-filled:before,
.icons8-panneau-stop:before,
.icons8-panneau-stop-1:before,
.icons8-paper-plane:before,
.icons8-paper-plane-2:before,
.icons8-pluie:before,
.icons8-pluie-1:before,
.icons8-road:before,
.icons8-road-2:before,
.icons8-search:before,
.icons8-search-filled:before,
.icons8-settings:before,
.icons8-settings-filled:before,
.icons8-shake-phone:before,
.icons8-shake-phone-1:before,
.icons8-shield:before,
.icons8-shield-2:before,
.icons8-sms:before,
.icons8-sms-2:before,
.icons8-social-group:before,
.icons8-social-group-2:before,
.icons8-soleil:before,
.icons8-soleil-1:before,
.icons8-sort-right:before,
.icons8-sort-right-filled:before,
.icons8-star:before,
.icons8-star-2:before,
.icons8-temps:before,
.icons8-temps-1:before,
.icons8-temps-venteux:before,
.icons8-temps-venteux-1:before,
.icons8-tick-box:before,
.icons8-tick-box-filled:before,
.icons8-timeline:before,
.icons8-timeline-2:before,
.icons8-toggle-off:before,
.icons8-toggle-off-filled:before,
.icons8-toggle-on:before,
.icons8-toggle-on-filled:before,
.icons8-trash:before,
.icons8-trash-filled:before,
.icons8-trophy:before,
.icons8-trophy-filled:before,
.icons8-unchecked-checkbox:before,
.icons8-unchecked-checkbox-filled:before,
.icons8-user:before,
.icons8-user-2:before,
.icons8-user-options:before,
.icons8-user-options-2:before,
.icons8-vitesse:before,
.icons8-zoom-in:before,
.icons8-zoom-in-filled:before,
.icons8-zoom-out:before,
.icons8-zoom-out-filled:before {
  display: inline-block;
  font-family: "DriveQuant-Fleet";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.icons8-add:before { content: "\f13a"; }
.icons8-add-2:before { content: "\f13b"; }
.icons8-add-3:before { content: "\f14d"; }
.icons8-add-4:before { content: "\f166"; }
.icons8-add-5:before { content: "\f167"; }
.icons8-add-filled:before { content: "\f14c"; }
.icons8-arrow-pointing-to-right:before { content: "\f15c"; }
.icons8-arrow-pointing-to-right-1:before { content: "\f15d"; }
.icons8-back:before { content: "\f160"; }
.icons8-back-filled:before { content: "\f161"; }
.icons8-bell:before { content: "\f168"; }
.icons8-bell-2:before { content: "\f169"; }
.icons8-brouillard-de-jour:before { content: "\f19a"; }
.icons8-brouillard-de-jour-1:before { content: "\f19b"; }
.icons8-brouillard-de-nuit:before { content: "\f1a0"; }
.icons8-brouillard-de-nuit-1:before { content: "\f1a1"; }
.icons8-calendar:before { content: "\f10a"; }
.icons8-calendar-filled:before { content: "\f10b"; }
.icons8-cancel:before { content: "\f140"; }
.icons8-cancel-filled:before { content: "\f141"; }
.icons8-car:before { content: "\f112"; }
.icons8-car-2:before { content: "\f113"; }
.icons8-car-3:before { content: "\f114"; }
.icons8-car-4:before { content: "\f115"; }
.icons8-centre-direction:before { content: "\f16e"; }
.icons8-centre-direction-1:before { content: "\f16f"; }
.icons8-cercl--a:before { content: "\f17c"; }
.icons8-cercl--a-1:before { content: "\f17d"; }
.icons8-cercl--b:before { content: "\f17e"; }
.icons8-cercl--b-1:before { content: "\f17f"; }
.icons8-checked:before { content: "\f13c"; }
.icons8-checked-checkbox:before { content: "\f150"; }
.icons8-checked-checkbox-filled:before { content: "\f151"; }
.icons8-checked-filled:before { content: "\f13d"; }
.icons8-checkmark:before { content: "\f148"; }
.icons8-checkmark-filled:before { content: "\f149"; }
.icons8-circled-down-right-2:before { content: "\f126"; }
.icons8-circled-down-right-2-filled:before { content: "\f127"; }
.icons8-circled-up-right-2:before { content: "\f124"; }
.icons8-circled-up-right-2-filled:before { content: "\f125"; }
.icons8-collapse-arrow:before { content: "\f162"; }
.icons8-collapse-arrow-filled:before { content: "\f163"; }
.icons8-compteur-de-vitesse:before { content: "\f187"; }
.icons8-delete:before { content: "\f100"; }
.icons8-delete-filled:before { content: "\f101"; }
.icons8-doughnut-chart:before { content: "\f136"; }
.icons8-doughnut-chart-2:before { content: "\f137"; }
.icons8-drop-down-arrow:before { content: "\f15a"; }
.icons8-drop-down-arrow-2:before { content: "\f15b"; }
.icons8-drop-down-arrow-3:before { content: "\f15e"; }
.icons8-drop-down-arrow-4:before { content: "\f15f"; }
.icons8-effacer-les-filtres:before { content: "\f178"; }
.icons8-effacer-les-filtres-2:before { content: "\f179"; }
.icons8-en-construction:before { content: "\f19e"; }
.icons8-en-construction-1:before { content: "\f19f"; }
.icons8-explanation-mark:before { content: "\f110"; }
.icons8-explanation-mark-1:before { content: "\f111"; }
.icons8-external-link:before { content: "\f146"; }
.icons8-external-link-filled:before { content: "\f147"; }
.icons8-filtre:before { content: "\f174"; }
.icons8-filtre-1:before { content: "\f175"; }
.icons8-filtrer-et-trier:before { content: "\f17a"; }
.icons8-filtrer-et-trier-1:before { content: "\f17b"; }
.icons8-forward-button:before { content: "\f128"; }
.icons8-forward-button-filled:before { content: "\f129"; }
.icons8-gauche:before { content: "\f188"; }
.icons8-gauche-1:before { content: "\f189"; }
.icons8-gr-le:before { content: "\f194"; }
.icons8-gr-le-1:before { content: "\f195"; }
.icons8-group:before { content: "\f118"; }
.icons8-group-2:before { content: "\f119"; }
.icons8-help:before { content: "\f10c"; }
.icons8-help-2:before { content: "\f10d"; }
.icons8-histogramme:before { content: "\f172"; }
.icons8-histogramme-1:before { content: "\f173"; }
.icons8-hiver:before { content: "\f19c"; }
.icons8-hiver-1:before { content: "\f19d"; }
.icons8-info:before { content: "\f10e"; }
.icons8-info-2:before { content: "\f10f"; }
.icons8-journey:before { content: "\f132"; }
.icons8-journey-filled:before { content: "\f133"; }
.icons8-leaderboard:before { content: "\f122"; }
.icons8-leaderboard-filled:before { content: "\f123"; }
.icons8-leaf:before { content: "\f16a"; }
.icons8-leaf-1:before { content: "\f16b"; }
.icons8-lock:before { content: "\f142"; }
.icons8-lock-filled:before { content: "\f143"; }
.icons8-lune-et--toiles:before { content: "\f18c"; }
.icons8-lune-et--toiles-1:before { content: "\f18d"; }
.icons8-male-user:before { content: "\f11e"; }
.icons8-male-user-2:before { content: "\f11f"; }
.icons8-marqueur-a:before { content: "\f180"; }
.icons8-marqueur-a-1:before { content: "\f181"; }
.icons8-marqueur-b:before { content: "\f182"; }
.icons8-marqueur-b-1:before { content: "\f183"; }
.icons8-menu:before { content: "\f108"; }
.icons8-menu-filled:before { content: "\f109"; }
.icons8-modifier-le-filtre:before { content: "\f176"; }
.icons8-modifier-le-filtre-1:before { content: "\f177"; }
.icons8-neige:before { content: "\f190"; }
.icons8-neige-1:before { content: "\f191"; }
.icons8-nuages:before { content: "\f18a"; }
.icons8-nuages-1:before { content: "\f18b"; }
.icons8-ok:before { content: "\f14a"; }
.icons8-ok-filled:before { content: "\f14b"; }
.icons8-orage:before { content: "\f192"; }
.icons8-orage-1:before { content: "\f193"; }
.icons8-padlock:before { content: "\f144"; }
.icons8-padlock-filled:before { content: "\f145"; }
.icons8-panneau-stop:before { content: "\f184"; }
.icons8-panneau-stop-1:before { content: "\f185"; }
.icons8-paper-plane:before { content: "\f138"; }
.icons8-paper-plane-2:before { content: "\f139"; }
.icons8-pluie:before { content: "\f18e"; }
.icons8-pluie-1:before { content: "\f18f"; }
.icons8-road:before { content: "\f134"; }
.icons8-road-2:before { content: "\f135"; }
.icons8-search:before { content: "\f102"; }
.icons8-search-filled:before { content: "\f103"; }
.icons8-settings:before { content: "\f106"; }
.icons8-settings-filled:before { content: "\f107"; }
.icons8-shake-phone:before { content: "\f16c"; }
.icons8-shake-phone-1:before { content: "\f16d"; }
.icons8-shield:before { content: "\f12c"; }
.icons8-shield-2:before { content: "\f12d"; }
.icons8-sms:before { content: "\f12e"; }
.icons8-sms-2:before { content: "\f12f"; }
.icons8-social-group:before { content: "\f11a"; }
.icons8-social-group-2:before { content: "\f11b"; }
.icons8-soleil:before { content: "\f198"; }
.icons8-soleil-1:before { content: "\f199"; }
.icons8-sort-right:before { content: "\f164"; }
.icons8-sort-right-filled:before { content: "\f165"; }
.icons8-star:before { content: "\f12a"; }
.icons8-star-2:before { content: "\f12b"; }
.icons8-temps:before { content: "\f170"; }
.icons8-temps-1:before { content: "\f171"; }
.icons8-temps-venteux:before { content: "\f196"; }
.icons8-temps-venteux-1:before { content: "\f197"; }
.icons8-tick-box:before { content: "\f13e"; }
.icons8-tick-box-filled:before { content: "\f13f"; }
.icons8-timeline:before { content: "\f130"; }
.icons8-timeline-2:before { content: "\f131"; }
.icons8-toggle-off:before { content: "\f152"; }
.icons8-toggle-off-filled:before { content: "\f153"; }
.icons8-toggle-on:before { content: "\f154"; }
.icons8-toggle-on-filled:before { content: "\f155"; }
.icons8-trash:before { content: "\f104"; }
.icons8-trash-filled:before { content: "\f105"; }
.icons8-trophy:before { content: "\f120"; }
.icons8-trophy-filled:before { content: "\f121"; }
.icons8-unchecked-checkbox:before { content: "\f14e"; }
.icons8-unchecked-checkbox-filled:before { content: "\f14f"; }
.icons8-user:before { content: "\f116"; }
.icons8-user-2:before { content: "\f117"; }
.icons8-user-options:before { content: "\f11c"; }
.icons8-user-options-2:before { content: "\f11d"; }
.icons8-vitesse:before { content: "\f186"; }
.icons8-zoom-in:before { content: "\f156"; }
.icons8-zoom-in-filled:before { content: "\f157"; }
.icons8-zoom-out:before { content: "\f158"; }
.icons8-zoom-out-filled:before { content: "\f159"; }

.popover {
max-width: 600px;
width: 500px;
background-color : #00EBB8;
}

.popover.bs-tether-element-attached-bottom::after, .popover.popover-top::after {

background-color : #00EBB8;
border: 1px solid black;
}

.popover.bs-tether-element-attached-bottom::before, .popover.popover-top::before {
border-top-color: #00EBB8;
}

.noUi-horizontal {
height: 4px;
background-color: #C5D0DE;
}

.noUi-horizontal .noUi-handle {
border-radius: 50%;
width: 16px;
height: 16px;
left: -17px;
top: -6px;
}

.noUi-handle:before, .noUi-handle:after {
display: none;
}

.noUi-handle {
background-color: #00EBB8;
}


.noUi-handle:focus {
outline: 0;
}

.noUi-tooltip {
border: 0;
background: transparent;
padding: 0;
color: #354052;
font-size: 14px;
font-weight: bold;
}

.btn-standard {
font-size: 14px;
padding: 8px 12px;
font-weight: 700;
background-color: #FFFFFF;
color: #354052;
border-radius: 6px;
border: 2px solid rgba(0,0,0,0.1);
}

.btn-standard:disabled {
color: #B0B0B1;
background-color: rgba(10,10,10,0.08);
}

.btn-standard:hover,
.btn-standard:focus {
background-color: #f3f5f9;
color: #354052;
}

.btn-selected-option {
position: relative;
text-align: left;
font-size: 14px;
padding: 8px 12px;
padding-right: 32px;
font-weight: 700;
background-color: #FFFFFF;
color: #354052;
border-radius: 6px;
border: 2px solid rgba(0,0,0,0.1);
width:100%;
}

.btn-selected-option:disabled {
color: #B0B0B1;
background-color: rgba(10,10,10,0.08);
}

.btn-selected-option:hover,
.btn-selected-option:focus {
background-color: #f3f5f9;
color: #354052;
}

.btn-selected-option > i {
position: absolute;
top: 10px;
right: 12px;
}

.dropdown-item {
background-color: #FFFFFF;
font-size: 14px;
color: #354052;
font-weight: bold;
}

.dropdown-item::first-letter {
text-transform: uppercase;
}

.dropdown-item:not([disabled="true"]):not(:disabled):hover {
background-color: #F1F4F8;
font-size: 14px;
color: #1EE8A8;
font-weight: bold;
}

.dropdown-item {
padding-left: 6px;
padding-right: 6px;
}

.dropdown-item:not(:first-child) {
border-top: 1px solid #DFE3E9;
}

.dropdown-item:first-child {
border-top-left-radius: 5px;
border-top-right-radius: 5px;
}

.dropdown-item:last-child {
border-bottom-left-radius: 5px;
border-bottom-right-radius: 5px;
}

.dropdown-menu {
border-radius: 5px;
border: 1px solid #DFE3E9;
}

.dropdown-toggle::after {
display: none;
}

button:focus {
outline: 0;
}

.pagination {
float: right;
margin-right: 15px;
background-color: #FFFFFF;
border: 3px solid #F5F8FA;
}

.pagination li {
margin-right: 0;
}

.pagination li>a, .pagination li>span {
color: #354052;
border-left: 0;
}

.page-item.active .page-link {
background-color: #00EBB8;
color: #FFFFFF;
border-color: #F5F8FA;

}

.pagination li.active>a, .pagination li.active>span, .pagination li.active>a:hover, .pagination li.active>span:hover, .pagination li.active>a:focus, .pagination li.active>span:focus {
background-color: #00EBB8;
color: #FFFFFF;
}

.pagination li>a:hover, .pagination li>span:hover, .pagination li>a:focus, .pagination li>span:focus {
background-color: #00EBB8;
color: #FFFFFF;
}

.btn-green {
font-size: 14px;
padding: 8px 12px;
font-weight: 700;
background-color: #00EBB8;
color: #FFFFFF;
border-radius: 6px;
border: 2px solid rgba(0,0,0,0.1);
}

.btn-red {
font-size: 14px;
padding: 8px 12px;
font-weight: 700;
background-color: #FF796F;
color: #FFFFFF;
border-radius: 6px;
border: 2px solid rgba(0,0,0,0.1);
}

.card {
background-color: transparent;
border: none;
}

.card-body {
padding: 0;
}

.card-header {
padding: 0;
border: 0;
background-color: transparent;
}

.btn-green:hover {
background-color: #29EEC3;
}

.btn-green:active {
background-color: #01D9AA;
}

.btn-green:focus {
background-color: #01D9AA;
}

.btn-green:disabled {
color: #B0B0B1;
background-color: rgba(10,10,10,0.08);
}

.btn-red:hover {
background-color: #FF8E86;
}

.btn-red:active {
background-color: #EC7067;
}

.btn-red:focus {
background-color: #FF8E86;
}

.btn-red:disabled {
color: #B0B0B1;
background-color: rgba(10,10,10,0.08);
}

.alert-success {
color: #FFFFFF;
background-color: #00EBB8;
}

.alert-warning {
color: #FFFFFF;
background-color: #F7A334;
}

.alert {
margin-bottom: 0;
font-size: 14px;
font-weight: 700;
border-radius: 0;
line-height: 21px;
border: none;
}

.alert-dismissible .close {
opacity: 1;
text-shadow: none;
color: #F1F4F8;
}

.alert-dismissible .close:focus {
color: #F1F4F8;
}

.btn:not([disabled="true"]):not(:disabled),
button:not([disabled="true"]):not(:disabled),
.btn:not(.disabled),
button:not(.disabled) {
cursor: pointer;
}

.activeTab,
.btn:not([disabled="true"]):not(:disabled).activeTab {
cursor: default;
}

.btn[disabled="true"],
.btn:disabled,
button[disabled="true"],
button:disabled,
.btn.disabled,
button.disabled {
cursor: default;
color: #B0B0B1;
background-color: rgba(10,10,10,0.08);
}

body, html {
font-family: 'Roboto';
height: 100%;
background-color: #ECEFF0;
overflow-x: hidden;
}

.loading-screen {
background-color: #083B54;
height: 100vh;
}

.loading-icon {
color: #fcfcfc;
}

.loading-position {
padding-top: 45vh;
}

.modal-xxl .modal-lg {
max-width: 80%;
}

.tooltip-inner {
min-width: 400px;
max-width: 500px;
}


.cdk-global-scrollblock {
  position: static;
  overflow: hidden !important;
  top: 0 !important;
}

.graph-box {
padding: 30px;
width: 100%;
}

.graph-box .content {
padding: 30px;
}

.has-error .input-group-addon {
  border-right: 2px solid;
}

.graph-box .content h4 {
margin: 0;
margin-bottom: 20px;
font-size: 18px;
line-height: 28px;
font-weight: 400;
}

.graph-box .content {
display: inline-block;
vertical-align: top;
}

.graph-box .content.graph {
background-color: #FFFFFF;
color: black;
border-radius: 6px;
width: 75%;
}

.graph-box .content.ctrl {
width: 25%;
padding: 0;
padding-top: 30px;
padding-bottom: 30px;
padding-left: 45px;
}

.graph-box .ctrl {
padding-left: 15px;
padding-right: 15px;
}

.graph-box .ctrl h4 {
margin-top: 40px;
margin-bottom: 30px;
}

.has-danger {
  border-color: #e74c3c !important;
}

.error-message {
  font-size: 11px;
  color: #e74c3c;
}

input,
textarea {
outline: none;
}

/* Invalid email in red*/
input[type="email"]:invalid:not(:focus) {
  color: red;
}

@media (max-width: 767px) {

  .graph-box .chart {
  padding-left: 0;
  }

  .graph-box .content.graph {
  width: 100%;
  }

  .graph-box .content.ctrl {
  padding-left: 0;
  width: 100%;
  }
}

@media (max-width: 1199px) {

  .modal-xxl .modal-lg {
  max-width: 90%;
  }
}

@media screen and (max-width: 768px) {

  .modal-xxl .modal-lg {
  max-width: 100%;
  }
}
/* This is a test commit. Do not care, please. */

